import toastr from 'toastr';
import { OK, CONFLICT, NOT_FOUND } from './statuses';

export function addVacancyNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The vacancy already exists');
  } if (status === OK) {
    return toastr.success('New vacancy was created successfully!');
  }
}

export function deleteVacancyDataNote(status) {
  if (status === OK) {
    return toastr.success('The vacancy was deleted successfully!');
  }
}

export function getVacancyListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no vacancies yet!');
  }
}

export function updateVacancyDataNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The vacancy already exists in the list');
  }
  if (status === OK) {
    return toastr.success('The vacancy data was edited successfully!');
  }
}
