import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import authReducer from './reducers/authReducer';
import uiReducer from './reducers/uiReducer';
import membersReducer from './reducers/membersReducer';
import partnersReducer from './reducers/partnersReducer';
import candidatesReducer from './reducers/candidatesReducer';
import projectsReducer from './reducers/projectsReducer';
import vacationsReducer from './reducers/vacationsReducer';
import ticketReducer from './reducers/ticketReducer';
import eventsReducer from './reducers/eventsReducer';
import vacanciesReducer from './reducers/vacanciesReducer';
import tasksReducer from './reducers/tasksReducer';
import holidaysReducer from './reducers/holidaysReducer';

const middlewaresArr = [thunkMiddleware];
let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
}

const middlewares = applyMiddleware(...middlewaresArr);
const rootReducer = combineReducers({
  authReducer,
  uiReducer,
  membersReducer,
  partnersReducer,
  candidatesReducer,
  projectsReducer,
  vacationsReducer,
  ticketReducer,
  eventsReducer,
  vacanciesReducer,
  tasksReducer,
  holidaysReducer,
});

export const store = createStore(rootReducer, composeEnhancers(middlewares));
