import toastr from 'toastr';
import { OK, CONFLICT, NOT_FOUND } from './statuses';

export function addHolidayNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The Holiday already exists in the list!');
  } if (status === OK) {
    return toastr.success('New Holiday was created successfully!');
  }
}

export function deleteHolidayNote(status) {
  if (status === OK) {
    return toastr.success('The Holiday was deleted successfully!');
  }
}

export function getHolidaysNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no Holidays yet!');
  }
}

export function addBulkHolidaysNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('There was an holiday created for that year, please add the remaining manually');
  } if (status === NOT_FOUND) {
    return toastr.warning('Holidays of the previous year have not been created for processing dublication.');
  } if (status === OK) {
    return toastr.success('Holidays were copied successfully for the  next year!');
  }
}
