import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  candidatesList: {
    totalPages: 1,
    currentPage: ' ',
    candidates: [],
  },
  getListSuccess: false,
  addCandidateSuccess: false,
  updateCandidateSuccess: false,
  deleteCandidateSuccess: false,
  candidateData: {},
};

export default function candidatesReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_CANDIDATESLIST_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_CANDIDATESLIST_SUCCESS:
    return {
      ...state,
      loading: false,
      candidatesList: action.payload,
      getListSuccess: true,
    };
  case actionTypes.GET_CANDIDATESLIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      candidatesList: {},
      getListSuccess: false,
    };
  case actionTypes.ADD_CANDIDATE_REQUEST:
    return {
      ...state,
      loading: true,
      addCandidateSuccess: false,
    };
  case actionTypes.ADD_CANDIDATE_SUCCESS:
    const newList = [...state.candidatesList.candidates];
    newList.push(action.candidateData);
    return {
      ...state,
      loading: false,
      candidatesList: { ...state.candidatesList, candidates: newList },
      addCandidateSuccess: true,
    };
  case actionTypes.ADD_CANDIDATE_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addCandidateSuccess: false,
    };

  case actionTypes.UPDATE_CANDIDATEDATA_REQUEST:
    return {
      ...state,
      loading: true,
      updateCandidateSuccess: false,
    };
  case actionTypes.UPDATE_CANDIDATEDATA_SUCCESS:
    const list = [...state.candidatesList.candidates];

    const { candidtateId, candidateHistory } = action;

    const index = list.findIndex(({ _id }) => _id === candidtateId);
    list[index] = {
      ...list[index],
      history: candidateHistory,
    };
    return {
      ...state,
      loading: false,
      updateCandidateSuccess: true,
      candidatesList: { ...state.candidatesList, candidates: list },
    };
  case actionTypes.UPDATE_CANDIDATEDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      updateCandidateSuccess: false,
    };

  case actionTypes.DELETE_CANDIDATE_REQUEST:
    return {
      ...state,
      loading: true,
      deleteCandidateSuccess: false,
    };
  case actionTypes.DELETE_CANDIDATE_SUCCESS:
    return {
      ...state,
      loading: false,
      candidatesList: {
        ...state.candidatesList,
        candidates: _.filter(state.candidatesList.candidates,
          ({ _id }) => _id !== action.candidateId),
      },
      deleteCandidateSuccess: true,
    };
  case actionTypes.DELETE_CANDIDATE_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteCandidateSuccess: false,
    };

  case actionTypes.GET_CANDIDATEDATA_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case actionTypes.GET_CANDIDATEDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      candidateData: action.payload,
    };
  case actionTypes.GET_CANDIDATEDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case actionTypes.CHANGE_ITEM:
    return {
      ...state,
      ...action.data,
    };
  default:
    return state;
  }
}
