import toastr from 'toastr';
import { NO_CONTENT, NOT_FOUND } from './statuses';

export function deleteDocumentDataNote(status) {
  if (status === NO_CONTENT) {
    return toastr.success('Selected document  was deleted successfully!');
  }
  if (status === NOT_FOUND) {
    return toastr.warning('Something was wrong, please try again');
  }
}
