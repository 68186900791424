import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  ticketsList: {
    totalPages: 1,
    currentPage: ' ',
    tickets: [],
  },
  ticketData: {},
  getListSuccess: false,
  addTicketSuccess: false,
  editTicketSuccess: false,
  deleteTicketSuccess: false,
};

export default function ticketsReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_TICKETSLIST_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_TICKETSLIST_SUCCESS:
    return {
      ...state,
      loading: false,
      ticketsList: action.payload,
      getListSuccess: true,
    };
  case actionTypes.GET_TICKETSLIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getListSuccess: false,
    };

  case actionTypes.ADD_TICKET_REQUEST:
    return {
      ...state,
      loading: true,
      addTicketSuccess: false,
    };
  case actionTypes.ADD_TICKET_SUCCESS:
    return {
      ...state,
      loading: false,
      ticketsList: { ...state.ticketsList, tickets: [...state.ticketsList.tickets, action.payload] },
      addTicketSuccess: true,
    };
  case actionTypes.ADD_TICKET_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addTicketSuccess: false,
    };
  case actionTypes.GET_TICKETDATA_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case actionTypes.GET_TICKETDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      ticketData: action.payload,
    };
  case actionTypes.GET_TICKETDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case actionTypes.EDIT_TICKETDATA_REQUEST:
    return {
      ...state,
      loading: true,
      editTicketSuccess: false,
    };
  case actionTypes.EDIT_TICKETDATA_SUCCESS: {
    const list = [...state.ticketsList.tickets];
    const id = action.ticketData._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = action.ticketData;
    return {
      ...state,
      loading: false,
      ticketsList: { ...state.ticketsList, tickets: list },
      ticketData: action.ticketData,
      editTicketSuccess: true,
    };
  }

  case actionTypes.EDIT_TICKETDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editTicketSuccess: false,
    };
  case actionTypes.EDIT_TICKETSTATUS_REQUEST:
    return {
      ...state,
      loading: true,
      editTicketSuccess: false,
    };
  case actionTypes.EDIT_TICKETSTATUS_SUCCESS: {
    const list = [...state.ticketsList.tickets];
    const id = action.ticketData._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = action.ticketData;
    return {
      ...state,
      loading: false,
      ticketsList: { ...state.ticketsList, tickets: list },
      ticketData: action.ticketData,
      editTicketSuccess: true,
    };
  }

  case actionTypes.EDIT_TICKETSTATUS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editTicketSuccess: false,
    };
  case actionTypes.DELETE_TICKET_REQUEST:
    return {
      ...state,
      loading: true,
      deleteTicketSuccess: false,
    };
  case actionTypes.DELETE_TICKET_SUCCESS:
    return {
      ...state,
      loading: false,
      ticketsList: {
        ...state.ticketsList,
        tickets: state.ticketsList.tickets.filter(({ _id }) => _id !== action.ticketId),
      },
      deleteTicketSuccess: true,
    };
  case actionTypes.DELETE_TICKET_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteTicketSuccess: false,
    };

  default:
    return state;
  }
}
