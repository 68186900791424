export const SIDEBAR_SHOW_HIDE = 'SIDEBAR_SHOW_HIDE';
export const DARK_LIGHT_MODE = 'DARK_LIGHT_MODE';

export function sidebarShowHide(text = '') {
  return {
    type: SIDEBAR_SHOW_HIDE,
    text,
  };
}

export function darkLightMode(text = '') {
  return {
    type: DARK_LIGHT_MODE,
    text,
  };
}
