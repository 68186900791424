import _ from 'lodash';
import { getToken } from './index';
import notificationHandlers from './assets/notification/index';

const apiUrl = process.env.REACT_APP_API_URL;

class Request {
  static tokenRefreshPromise;

  constructor(name = null) {
    this.name = name;
  }

  static authorize = async () => {
    // Authorize Static method is used for getting token. If the refrash token request is
    // still in the process of getting the reply from API, new request waits for it.
    if (Request.tokenRefreshPromise) await Request.tokenRefreshPromise;
    Request.tokenRefreshPromise = getToken();
    await Request.tokenRefreshPromise;
  }

  showNotification = (count) => {
    if (!_.isNil(this.name)) {
      if (!_.isNil(count)) notificationHandlers[this.name](this.status, count);
      else {
        notificationHandlers[this.name](this.status);
      }
    }
  }

  async get(endpoint = '', header = {}, downloadFile = false) {
    await Request.authorize();
    const token = _.isNil(localStorage.getItem('tokens')) ? '' : JSON.parse(localStorage.getItem('tokens')).accessToken;
    return fetch(`${apiUrl}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        ...header,
      },
    }).then((response) => {
      this.status = response.status;
      if (response.ok) return downloadFile ? response.blob() : response.json();
      return Promise.reject(response);
    })
      .catch((err) => {
        this.status = err.status;
        return err;
      });
  }

  async post(endpoint = '', data = {}, additionalHeaders = {}, isFormData) {
    await Request.authorize();
    const token = _.isNil(localStorage.getItem('tokens')) ? '' : JSON.parse(localStorage.getItem('tokens')).accessToken;
    const headers = {
      authorization: `Bearer ${token}`,
      ...additionalHeaders,
    };
    let body = data;
    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(data);
    }
    return fetch(`${apiUrl}${endpoint}`, {
      method: 'POST',
      headers,
      body,
    })
      .then((response) => {
        this.status = response.status;
        return response.ok ? response.json() : Promise.reject(response);
      });
  }

  async put(endpoint = '', data = {}, additionalHeaders = {}, isFormData) {
    await Request.authorize();
    const token = _.isNil(localStorage.getItem('tokens')) ? '' : JSON.parse(localStorage.getItem('tokens')).accessToken;
    const headers = {
      authorization: `Bearer ${token}`,
      ...additionalHeaders,
    };
    let body = data;
    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(data);
    }
    return fetch(`${apiUrl}${endpoint}`, {
      method: 'PUT',
      headers,
      body,
    })
      .then((response) => {
        this.status = response.status;
        return response.ok && response.status !== 204 ? response.json() : response;
      });
  }

  async del(endpoint = '', header = {}, body = {}) {
    await Request.authorize();
    const token = _.isNil(localStorage.getItem('tokens')) ? '' : JSON.parse(localStorage.getItem('tokens')).accessToken;
    return fetch(`${apiUrl}${endpoint}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        ...header,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        this.status = response.status;
        return response;
      });
  }

  async patch(endpoint = '', data = {}, header = {}) {
    await Request.authorize();
    const token = _.isNil(localStorage.getItem('tokens')) ? '' : JSON.parse(localStorage.getItem('tokens')).accessToken;
    return fetch(`${apiUrl}${endpoint}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        ...header,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.status = response.status;
        return response.ok ? response.json() : Promise.reject(response);
      });
  }
}

export default Request;
