import CIcon from '@coreui/icons-react';
import { CLink } from '@coreui/react';
import React from 'react';
import Choice from '../../../images/choice.png';
import './style.css';

const ThankYou = () => (
  <div className="thank-you-container">
    <img className="choice-img" src={Choice} alt="" />
    <h1>THANK YOU</h1>
    <p>
      For your interest in Preezma. We will review your application and
      reply as soon as it&rsquo;s possible.
    </p>
    <CLink href="https://www.facebook.com/PreezmaSoftware" target="_blank">
      <CIcon name="cib-facebook" title="Facebook" size="lg" />
    </CLink>
    <CLink href="https://www.linkedin.com/company/preezma/" target="_blank">
      <CIcon name="cib-linkedin" title="Linkedin" size="lg" />
    </CLink>
    <CLink href="https://www.preezma.com" target="_blank">
      <img src="logo/cropped-icon-32x32.png" alt="" />
    </CLink>
  </div>
);

export default ThankYou;
