import _ from 'lodash';
import moment from 'moment';
import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  categoriesList: {
    totalPages: 1,
    currentPage: ' ',
    categories: [],
  },
  tasksList: {
    totalPages: 1,
    currentPage: ' ',
    tasks: [],
  },
  tasksPerUser: { tasks: [] },
  taskData: {},
  getCategoriesListSuccess: false,
  getListSuccess: false,
  addTaskSuccess: false,
  editTaskSuccess: false,
  deleteTaskSuccess: false,
  addCategorySuccess: false,
  editCategorySuccess: false,
  deleteCategorySuccess: false,
  addBulkTaskSuccess: false,
};

export default function tasksReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_CATEGORIES_REQUEST:
    return {
      ...state,
      loading: true,
      getCategoriesListSuccess: false,
    };
  case actionTypes.GET_CATEGORIES_SUCCESS:
    return {
      ...state,
      loading: false,
      categoriesList: action.payload,
      getCategoriesListSuccess: true,
    };
  case actionTypes.GET_CATEGORIES_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getCategoriesListSuccess: false,
    };
  case actionTypes.DELETE_CATEGORY_REQUEST:
    return {
      ...state,
      loading: true,
      deleteCategorySuccess: false,
    };
  case actionTypes.DELETE_CATEGORY_SUCCESS:
    let categories = [...state.categoriesList.categories];
    action.payload.forEach((id) => {
      categories = categories.filter((category) => category._id !== id);
        });
    return {
      ...state,
      loading: false,
      categoriesList: {
        ...state.categoriesList,
      categories,
      },
      deleteCategorySuccess: true,
    };
  case actionTypes.DELETE_CATEGORY_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteCategorySuccess: false,
    };
    case actionTypes.GET_TASKSLIST_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_TASKSLIST_SUCCESS:
    return {
      ...state,
      loading: false,
      [action.tasksType]: action.payload,
      getListSuccess: true,
    };
  case actionTypes.GET_TASKSLIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getListSuccess: false,
    };

  case actionTypes.ADD_TASK_REQUEST:
    return {
      ...state,
      loading: true,
      addTaskSuccess: false,
    };
  case actionTypes.ADD_TASK_SUCCESS:
    const listOfIds = [];
    _.forEach(state.categoriesList.categories, (category) => { listOfIds.push(category._id); });
    const newCategory = _.filter(action.taskData.categories, (category) => !_.includes(listOfIds, category._id));
    return {
      ...state,
      loading: false,
      tasksList: { ...state.tasksList, tasks: [...state.tasksList.tasks, action.taskData] },
      categoriesList: { ...state.categoriesList, categories: [...state.categoriesList.categories, ...newCategory] },
      addTaskSuccess: true,
      taskData: action.taskData,
    };
  case actionTypes.ADD_TASK_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addTaskSuccess: false,
    };
    case actionTypes.ADD_BULKTASKS_REQUEST:
    return {
      ...state,
      loading: true,
      addBulkTaskSuccess: false,
    };
  case actionTypes.ADD_BULKTASKS_SUCCESS:
    const stateList = [...state.tasksPerUser.tasks];
    const newlyAssignedDueDate = moment(action.payload.bulkNewTasks[0].dueDate).format('ll');
    const newIndex = _.findIndex(state.tasksPerUser.tasks, (task) => moment(task.dueDate).format('ll') > newlyAssignedDueDate);
    stateList.splice(newIndex, 0, ...action.payload.bulkNewTasks);
    return {
      ...state,
      loading: false,
      tasksPerUser: { ...state.tasksPerUser, tasks: stateList },
      addBulkTaskSuccess: true,
      count: action.payload.count,
    };
  case actionTypes.ADD_BULKTASKS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addBulkTaskSuccess: false,
    };
  case actionTypes.GET_TASKDATA_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case actionTypes.GET_TASKDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      taskData: action.payload,
    };
  case actionTypes.GET_TASKDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case actionTypes.EDIT_TASKDATA_REQUEST:
    return {
      ...state,
      loading: true,
      editTaskSuccess: false,
    };
  case actionTypes.EDIT_TASKDATA_SUCCESS: {
    const newList = [...state.tasksList.tasks];
    const id = action.taskData._id;
    const index = newList.findIndex(({ _id }) => _id === id);
    newList[index] = action.taskData;
     const newListPerUser = [...state.tasksPerUser.tasks];
    const changeIndex = newListPerUser.findIndex(({ _id }) => _id === id);
    newListPerUser[changeIndex] = action.taskData;
    const listOfIds = [];
    _.forEach(state.categoriesList.categories, (category) => { listOfIds.push(category._id); });
    const newCategory = _.filter(action.taskData.categories, (category) => !_.includes(listOfIds, category._id));
    return {
      ...state,
      loading: false,
      tasksList: { ...state.tasksList, tasks: newList },
      categoriesList: { ...state.categoriesList, categories: [...state.categoriesList.categories, ...newCategory] },
      editTaskSuccess: true,
      tasksPerUser: { ...state.tasksPerUser, tasks: newListPerUser },
    };
  }

  case actionTypes.EDIT_TASKDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editTaskSuccess: false,
    };
  case actionTypes.DELETE_TASK_REQUEST:
    return {
      ...state,
      loading: true,
      deleteTaskSuccess: false,
    };
  case actionTypes.DELETE_TASK_SUCCESS:
    return {
      ...state,
      loading: false,
      tasksList: {
        ...state.tasksList,
        tasks: state.tasksList.tasks.filter(({ _id }) => _id !== action.taskId),
      },
      tasksPerUser: {
        ...state.tasksPerUser,
        tasks: state.tasksPerUser.tasks.filter(({ _id }) => _id !== action.taskId),
      },
      deleteTaskSuccess: true,
    };
  case actionTypes.DELETE_TASK_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteTaskSuccess: false,
    };

  default:
    return state;
  }
}
