import * as actionTypes from './actionTypes';
import Request from '../../request';

const request = new Request();

export default function getCurrentUser(id) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_CURRENTUSER_REQUEST });
    request.get(`/user?userId=${id}`)
      .then((res) => {
        dispatch({ type: actionTypes.GET_CURRENTUSER_SUCCESS, currentUser: res });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.GET_CURRENTUSER_FAILURE, error: error.toString() });
      });
  };
}
