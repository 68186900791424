import toastr from 'toastr';
import { OK, CONFLICT, NOT_FOUND } from './statuses';

export function addProjectNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The project already exists');
  } if (status === OK) {
    return toastr.success('New project was created successfully!');
  }
}

export function deleteProjectDataNote(status) {
  if (status === OK) {
    return toastr.success('The project was deleted successfully!');
  }
}

export function getProjectListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no projects yet!');
  }
}

export function updateProjectDataNote(status) {
  if (status === OK) {
    return toastr.success('The project data was edited successfully!');
  } if (status === CONFLICT) {
    return toastr.warning('The project already exists in the list!');
  }
}
