export const SIGN_UP_PROCESS = 'SIGN_UP_PROCESS';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const LOG_IN_PROCESS = 'LOG_IN_PROCESS';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const TOKEN_REFRESH_REQUEST = 'TOKEN_REFRESH_REQUEST';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE';

export const GET_MEMBERSLIST_REQUEST = 'GET_MEMBERSLIST_REQUEST';
export const GET_MEMBERSLIST_SUCCESS = 'GET_MEMBERSLIST_SUCCESS';
export const GET_MEMBERSLIST_FAILURE = 'GET_MEMBERSLIST_FAILURE';

export const GET_USERDATA_REQUEST = 'GET_USERDATA_REQUEST';
export const GET_USERDATA_SUCCESS = 'GET_USERDATA_SUCCESS';
export const GET_USERDATA_FAILURE = 'GET_USERDATA_FAILURE';

export const INVITE_MEMBER_REQUEST = 'INVITE_MEMBER_REQUEST';
export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAILURE = 'INVITE_MEMBER_FAILURE';

export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE';

export const DELETE_MEMBER_PERFORMANCE_REQUEST = 'DELETE_MEMBER_PERFORMANCE_REQUEST';
export const DELETE_MEMBER_PERFORMANCE_SUCCESS = 'DELETE_MEMBER_PERFORMANCE_SUCCESS';
export const DELETE_MEMBER_PERFORMANCE_FAILURE = 'DELETE_MEMBER_PERFORMANCE_FAILURE';

export const DELETE_MEMBER_EMERGENCY_REQUEST = 'DELETE_MEMBER_EMERGENCY_REQUEST';
export const DELETE_MEMBER_EMERGENCY_SUCCESS = 'DELETE_MEMBER_EMERGENCY_SUCCESS';
export const DELETE_MEMBER_EMERGENCY_FAILURE = 'DELETE_MEMBER_EMERGENCY_FAILURE';

export const UPDATE_COMPANYDATA_REQUEST = 'UPDATE_COMPANYDATA_REQUEST';
export const UPDATE_COMPANYDATA_SUCCESS = 'UPDATE_COMPANYDATA_SUCCESS';
export const UPDATE_COMPANYDATA_FAILURE = 'UPDATE_COMPANYDATA_FAILURE';

export const GET_PARTNERSLIST_REQUEST = 'GET_PARTNERSDATA_REQUEST';
export const GET_PARTNERSLIST_SUCCESS = 'GET_PARTNERSDATA_SUCCESS';
export const GET_PARTNERSLIST_FAILURE = 'GET_PARTNERSDATA_FAILURE';

export const ADD_PARTNER_REQUEST = 'ADD_PARTNER_REQUEST';
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS';
export const ADD_PARTNER_FAILURE = 'ADD_PARTNER_FAILURE';

export const GET_PARTNERDATA_REQUEST = 'GET_PARTNERDATA_REQUEST';
export const GET_PARTNERDATA_SUCCESS = 'GET_PARTNERDATA_SUCCESS';
export const GET_PARTNERDATA_FAILURE = 'GET_PARTNERDATA_FAILURE';

export const EDIT_PARTNERDATA_REQUEST = 'EDIT_PARTNERDATA_REQUEST';
export const EDIT_PARTNERDATA_SUCCESS = 'EDIT_PARTNERDATA_SUCCESS';
export const EDIT_PARTNERDATA_FAILURE = 'EDIT_PARTNERDATA_FAILURE';

export const DELETE_PARTNER_REQUEST = 'DELETE_PARTNER_REQUEST';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const DELETE_PARTNER_FAILURE = 'DELETE_PARTNER_FAILURE';

export const GET_CURRENTUSER_REQUEST = 'GET_CURRENTUSER_REQUEST';
export const GET_CURRENTUSER_SUCCESS = 'GET_CURRENTUSER_SUCCESS';
export const GET_CURRENTUSER_FAILURE = 'GET_CURRENTUSER_FAILURE';

export const UPDATE_CURRENTUSERDATA_REQUEST = 'UPDATE_CURRENTUSERDATA_REQUEST';
export const UPDATE_CURRENTUSERDATA_SUCCESS = 'UPDATE_CURRENTUSERDATA_SUCCESS';
export const UPDATE_CURRENTUSERDATA_FAILURE = 'UPDATE_CURRENTUSERDATA_FAILURE';

export const UPDATE_USERDATA_REQUEST = 'UPDATE_USERDATA_REQUEST';
export const UPDATE_USERDATA_SUCCESS = 'UPDATE_USERDATA_SUCCESS';
export const UPDATE_USERDATA_FAILURE = 'UPDATE_USERDATA_FAILURE';

export const GET_CANDIDATESLIST_REQUEST = 'GET_CANDIDATESLIST_REQUEST';
export const GET_CANDIDATESLIST_SUCCESS = 'GET_CANDIDATESLIST_SUCCESS';
export const GET_CANDIDATESLIST_FAILURE = 'GET_CANDIDATESLIST_FAILURE';

export const ADD_CANDIDATE_REQUEST = 'ADD_CANDIDATE_REQUEST';
export const ADD_CANDIDATE_SUCCESS = 'ADD_CANDIDATE_SUCCESS';
export const ADD_CANDIDATE_FAILURE = 'ADD_CANDIDATE_FAILURE';

export const UPDATE_CANDIDATEDATA_REQUEST = 'UPDATE_CANDIDATEDATA_REQUEST';
export const UPDATE_CANDIDATEDATA_SUCCESS = 'UPDATE_CANDIDATEDATA_SUCCESS';
export const UPDATE_CANDIDATEDATA_FAILURE = 'UPDATE_CANDIDATEDATA_FAILURE';

export const GET_CANDIDATEDATA_REQUEST = 'GET_CANDIDATEDATA_REQUEST';
export const GET_CANDIDATEDATA_SUCCESS = 'GET_CANDIDATEDATA_SUCCESS';
export const GET_CANDIDATEDATA_FAILURE = 'GET_CANDIDATEDATA_FAILURE';

export const DELETE_CANDIDATE_REQUEST = 'DELETE_CANDIDATE_REQUEST';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAILURE = 'DELETE_CANDIDATE_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_PROJECTSLIST_REQUEST = 'GET_PROJECTSLIST_REQUEST';
export const GET_PROJECTSLIST_SUCCESS = 'GET_PROJECTSLIST_SUCCESS';
export const GET_PROJECTSLIST_FAILURE = 'GET_PROJECTSLIST_FAILURE';

export const GET_PROJECTDATA_REQUEST = 'GET_PROJECTDATA_REQUEST';
export const GET_PROJECTDATA_SUCCESS = 'GET_PROJECTDATA_SUCCESS';
export const GET_PROJECTDATA_FAILURE = 'GET_PROJECTDATA_FAILURE';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const EDIT_PROJECTDATA_REQUEST = 'EDIT_PROJECTDATA_REQUEST';
export const EDIT_PROJECTDATA_SUCCESS = 'EDIT_PROJECTDATA_SUCCESS';
export const EDIT_PROJECTDATA_FAILURE = 'EDIT_PROJECTDATA_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const GET_VACATIONSLIST_REQUEST = 'GET_VACATIONSDATA_REQUEST';
export const GET_VACATIONSLIST_SUCCESS = 'GET_VACATIONSDATA_SUCCESS';
export const GET_VACATIONSLIST_FAILURE = 'GET_VACATIONSDATA_FAILURE';

export const GET_VACATIONSPERUSER_REQUEST = 'GET_VACATIONSPERUSER_REQUEST';
export const GET_VACATIONSPERUSER_SUCCESS = 'GET_VACATIONSPERUSER_SUCCESS';
export const GET_VACATIONSPERUSER_FAILURE = 'GET_VACATIONSPERUSER_FAILURE';

export const GET_VACATIONSHISTORY_REQUEST = 'GET_VACATIONSHISTORY_REQUEST';
export const GET_VACATIONSHISTORY_SUCCESS = 'GET_VACATIONSHISTORY_SUCCESS';
export const GET_VACATIONSHISTORY_FAILURE = 'GET_VACATIONSHISTORY_FAILURE';

export const ADD_VACATION_REQUEST = 'ADD_VACATION_REQUEST';
export const ADD_VACATION_SUCCESS = 'ADD_VACATION_SUCCESS';
export const ADD_VACATION_FAILURE = 'ADD_VACATION_FAILURE';

export const GET_VACATION_REQUEST = 'GET_VACATION_REQUEST';
export const GET_VACATION_SUCCESS = 'GET_VACATION_SUCCESS';
export const GET_VACATION_FAILURE = 'GET_VACATION_FAILURE';

export const EDIT_VACATIONDATA_REQUEST = 'EDIT_VACATIONDATA_REQUEST';
export const EDIT_VACATIONDATA_SUCCESS = 'EDIT_VACATIONDATA_SUCCESS';
export const EDIT_VACATIONDATA_FAILURE = 'EDIT_VACATIONDATA_FAILURE';

export const DELETE_VACATION_REQUEST = 'DELETE_VACATION_REQUEST';
export const DELETE_VACATION_SUCCESS = 'DELETE_VACATION_SUCCESS';
export const DELETE_VACATION_FAILURE = 'DELETE_VACATION_FAILURE';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const ADD_BULKTASKS_REQUEST = 'ADD_BULKTASKS_REQUEST';
export const ADD_BULKTASKS_SUCCESS = 'ADD_BULKTASKS_SUCCESS';
export const ADD_BULKTASKS_FAILURE = 'ADD_BULKTASKS_FAILURE';

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const EDIT_VACATIONSTATUS_REQUEST = 'EDIT_VACATIONSTATUS_REQUEST';
export const EDIT_VACATIONSTATUS_SUCCESS = 'EDIT_VACATIONSTATUS_SUCCESS';
export const EDIT_VACATIONSTATUS_FAILURE = 'EDIT_VACATIONSTATUS_FAILURE';

export const GET_TICKETSLIST_REQUEST = 'GET_TICKETSLIST_REQUEST';
export const GET_TICKETSLIST_SUCCESS = 'GET_TICKETSLIST_SUCCESS';
export const GET_TICKETSLIST_FAILURE = 'GET_TICKETSLIST_FAILURE';

export const GET_TICKETDATA_REQUEST = 'GET_TICKETDATA_REQUEST';
export const GET_TICKETDATA_SUCCESS = 'GET_TICKETDATA_SUCCESS';
export const GET_TICKETDATA_FAILURE = 'GET_TICKETDATA_FAILURE';

export const ADD_TICKET_REQUEST = 'ADD_TICKET_REQUEST';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';
export const ADD_TICKET_FAILURE = 'ADD_TICKET_FAILURE';

export const EDIT_TICKETDATA_REQUEST = 'EDIT_TICKETDATA_REQUEST';
export const EDIT_TICKETDATA_SUCCESS = 'EDIT_TICKETDATA_SUCCESS';
export const EDIT_TICKETDATA_FAILURE = 'EDIT_TICKETDATA_FAILURE';

export const EDIT_TICKETSTATUS_REQUEST = 'EDIT_TICKETSTATUS_REQUEST';
export const EDIT_TICKETSTATUS_SUCCESS = 'EDIT_TICKETSTATUS_SUCCESS';
export const EDIT_TICKETSTATUS_FAILURE = 'EDIT_TICKETSTATUS_FAILURE';

export const DELETE_TICKET_REQUEST = 'DELETE_TICKET_REQUEST';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_FAILURE = 'DELETE_TICKET_FAILURE';

export const GET_UPCOMINGEVENTS_REQUEST = 'GET_UPCOMINGEVENTS_REQUEST';
export const GET_UPCOMINGEVENTS_SUCCESS = 'GET_UPCOMINGEVENTS_SUCCESS';
export const GET_UPCOMINGEVENTS_FAILURE = 'GET_UPCOMINGEVENTS_FAILURE';

export const GET_PASTEVENTS_REQUEST = 'GET_PASTEVENTS_REQUEST';
export const GET_PASTEVENTS_SUCCESS = 'GET_PASTEVENTS_SUCCESS';
export const GET_PASTEVENTS_FAILURE = 'GET_PASTEVENTS_FAILURE';

export const GET_EVENTDATA_REQUEST = 'GET_EVENTDATA_REQUEST';
export const GET_EVENTDATA_SUCCESS = 'GET_EVENTDATA_SUCCESS';
export const GET_EVENTDATA_FAILURE = 'GET_EVENTDATA_FAILURE';

export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

export const UPDATE_EVENTDATA_REQUEST = 'UPDATE_EVENTDATA_REQUEST';
export const UPDATE_EVENTDATA_SUCCESS = 'UPDATE_EVENTDATA_SUCCESS';
export const UPDATE_EVENTDATA_FAILURE = 'UPDATE_EVENTDATA_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const GET_VACANCIESLIST_REQUEST = 'GET_VACANCIESLIST_REQUEST';
export const GET_VACANCIESLIST_SUCCESS = 'GET_VACANCIESLIST_SUCCESS';
export const GET_VACANCIESLIST_FAILURE = 'GET_VACANCIESLIST_FAILURE';

export const GET_VACANCYDATA_REQUEST = 'GET_VACANCYDATA_REQUEST';
export const GET_VACANCYDATA_SUCCESS = 'GET_VACANCYDATA_SUCCESS';
export const GET_VACANCYDATA_FAILURE = 'GET_VACANCYDATA_FAILURE';

export const ADD_VACANCY_REQUEST = 'ADD_VACANCY_REQUEST';
export const ADD_VACANCY_SUCCESS = 'ADD_VACANCY_SUCCESS';
export const ADD_VACANCY_FAILURE = 'ADD_VACANCY_FAILURE';

export const EDIT_VACANCYDATA_REQUEST = 'EDIT_VACANCYDATA_REQUEST';
export const EDIT_VACANCYDATA_SUCCESS = 'EDIT_VACANCYDATA_SUCCESS';
export const EDIT_VACANCYDATA_FAILURE = 'EDIT_VACANCYDATA_FAILURE';

export const DELETE_VACANCY_REQUEST = 'DELETE_VACANCY_REQUEST';
export const DELETE_VACANCY_SUCCESS = 'DELETE_VACANCY_SUCCESS';
export const DELETE_VACANCY_FAILURE = 'DELETE_VACANCY_FAILURE';

export const GET_TASKSLIST_REQUEST = 'GET_TASKSLIST_REQUEST';
export const GET_TASKSLIST_SUCCESS = 'GET_TASKSLIST_SUCCESS';
export const GET_TASKSLIST_FAILURE = 'GET_TASKSLIST_FAILURE';

export const GET_TASKDATA_REQUEST = 'GET_TASKDATA_REQUEST';
export const GET_TASKDATA_SUCCESS = 'GET_TASKDATA_SUCCESS';
export const GET_TASKDATA_FAILURE = 'GET_TASKDATA_FAILURE';

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';

export const EDIT_TASKDATA_REQUEST = 'EDIT_TASKDATA_REQUEST';
export const EDIT_TASKDATA_SUCCESS = 'EDIT_TASKDATA_SUCCESS';
export const EDIT_TASKDATA_FAILURE = 'EDIT_TASKDATA_FAILURE';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';

export const GET_HOLIDAYS_REQUEST = 'GET_HOLIDAYS_REQUEST';
export const GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
export const GET_HOLIDAYS_FAILURE = 'GET_HOLIDAYS_FAILURE';

export const ADD_HOLIDAY_REQUEST = 'ADD_HOLIDAY_REQUEST';
export const ADD_HOLIDAY_SUCCESS = 'ADD_HOLIDAY_SUCCESS';
export const ADD_HOLIDAY_FAILURE = 'ADD_HOLIDAY_FAILURE';

export const ADD_BULKHOLIDAYS_REQUEST = 'ADD_BULKHOLIDAYS_REQUEST';
export const ADD_BULKHOLIDAYS_SUCCESS = 'ADD_BULKHOLIDAYS_SUCCESS';
export const ADD_BULKHOLIDAYS_FAILURE = 'ADD_BULKHOLIDAYS_FAILURE';

export const DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';

export const SORT_BY_DECREASE = 'SORT_BY_DECREASE';
export const SORT_BY_GROW = 'SORT_BY_GROW';

export const CHANGE_ITEM = 'CHANGE_ITEM';

export const DELETE_MEMBER_DOCUMENT_REQUEST = 'DELETE_MEMBER_DOCUMENT_REQUEST';
export const DELETE_MEMBER_DOCUMENT_SUCCESS = 'DELETE_MEMBER_DOCUMENT_SUCCESS';
export const DELETE_MEMBER_DOCUMENT_FAILURE = 'DELETE_MEMBER_DOCUMENT_FAILURE';
