import toastr from 'toastr';
import { NO_CONTENT, NOT_FOUND } from './statuses';

export function deletePerformanceDataNote(status) {
  if (status === NO_CONTENT) {
    return toastr.success('Selected performance review  record was deleted successfully!');
  }
  if (status === NOT_FOUND) {
    return toastr.warning('There is no valid performance review record selected for deletion!');
  }
}
