import toastr from 'toastr';
import { NO_CONTENT, CONFLICT, OK, BAD_REQUEST, UNAUTHORIZED } from './statuses';

export function updateCurrentUserDataNote(status) {
  if (status === OK) {
    return toastr.success('Your information updated successfully!');
  } if (status === CONFLICT) {
    return toastr.warning('Email address already exists.');
  }
}

export function changeUserPasswordNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('Old password does not match!');
  } if (status === NO_CONTENT) {
    return toastr.success('Password changed successfully!');
  }
}

export function forgotPasswordNote(status) {
  if (status === BAD_REQUEST) {
    return toastr.warning('Please enter your registered email address!');
  } if (status === NO_CONTENT) {
    return toastr.success('Please check your email for completing password reset!');
  }
}

export function resetPasswordNote(status) {
  if (status === UNAUTHORIZED) {
    return toastr.warning('Not an authorized user or expired token!');
  } if (status === CONFLICT) {
    return toastr.warning('New password can not be the same as the old one');
  } if (status === NO_CONTENT) {
    return toastr.success('Successfully set up! You may login with new password!');
  }
}
