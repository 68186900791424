import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, isSignedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!isSignedIn ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: props.location.state
            ? props.location.state.from.pathname
            : '/',
          state: { from: props.location },
        }}
      />
    ))}
  />
);

export default PublicRoute;
