import toastr from 'toastr';
import { OK, CREATED, CONFLICT, NOT_FOUND } from './statuses';

export function addTaskNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The task with that name has been already created');
  } if (status === CREATED) {
    return toastr.success('New task template was created successfully!');
  }
}
export function assignBulkTasksNote(status, count) {
  if (status === NOT_FOUND) {
    return toastr.warning('There were no tasks selected to assign!');
  } if (status === CREATED) {
    return toastr.success(`${count} tasks were successfully assigned!`);
  }
}

export function deleteTaskDataNote(status) {
  if (status === OK) {
    return toastr.success('The task was deleted successfully!');
  }
}

export function getTaskListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no tasks yet!');
  }
}

export function updateTaskDataNote(status) {
  if (status === OK) {
    return toastr.success('The task data was edited successfully!');
  } if (status === CONFLICT) {
    return toastr.warning('The task already exists in the list!');
  }
}
