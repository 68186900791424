import * as actions from '../actions/uiActions';

const initialState = {
  sidebarShow: false,
  darkMode: JSON.parse(localStorage.getItem('uiMode')),
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
  case actions.SIDEBAR_SHOW_HIDE:
    return { ...state, sidebarShow: !state.sidebarShow };
  case actions.DARK_LIGHT_MODE:
    localStorage.setItem('uiMode', !state.darkMode);
    return { ...state, darkMode: !state.darkMode };
  default:
    return state;
  }
}
