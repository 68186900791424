import * as actionTypes from '../actions/actionTypes';
import getUserId from '../../helpers/getUserId';

const defaultState = {
  currentUser: { _id: getUserId() },
  signUpProcess: false,
  signUpSuccess: false,
  signUpFailure: false,
  error: null,
  expires_in: null,
  tokens: null,
  getCurrentUserSuccess: false,
  company: {},
  companyUpdateProcess: false,
  companyUpdateSuccess: false,
  userUpdateProcess: false,
  userUpdateSuccess: false,
};

export default function authReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SIGN_UP_PROCESS:
      return {
        ...state,
        signUpProcess: true,
      };
    case actionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpProcess: false,
        signUpSuccess: true,
        userData: action.userData,
      };
    case actionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        signUpFailure: true,
        signUpProcess: false,
        error: action.error,
      };
    case actionTypes.LOG_IN_PROCESS:
      return {
        ...state,
        logInProcess: true,
      };
    case actionTypes.LOG_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.currentUser,
        tokens: action.jwt,
        logInProcess: false,
        logInSuccess: true,
      };
    case actionTypes.LOG_IN_FAILURE:
      return {
        ...state,
        logInFailure: true,
        logInProcess: false,
        error: action.error,
      };

    case actionTypes.TOKEN_REFRESH_REQUEST:
      return {
        ...state,
      };
    case actionTypes.TOKEN_REFRESH_SUCCESS:
      return {
        ...state,
        tokens: action.data,
      };
    case actionTypes.TOKEN_REFRESH_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.LOGOUT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        signUpSuccess: false,
        currentUser: { _id: '' },
        tokens: null,
      };

    case actionTypes.GET_CURRENTUSER_REQUEST:
      return {
        ...state,
        loading: true,
        getCurrentUserSuccess: false,
      };
    case actionTypes.GET_CURRENTUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.currentUser,
        getCurrentUserSuccess: true,
      };
    case actionTypes.GET_CURRENTUSER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        currentUser: { _id: '' },
        getCurrentUserSuccess: false,
      };

    case actionTypes.UPDATE_CURRENTUSERDATA_REQUEST:
      return {
        ...state,
        userUpdateProcess: true,
        userUpdateSuccess: false,
      };
    case actionTypes.UPDATE_CURRENTUSERDATA_SUCCESS:
      return {
        ...state,
        userUpdateProcess: false,
        userUpdateSuccess: true,
        currentUser: action.payload,
      };
    case actionTypes.UPDATE_CURRENTUSERDATA_FAILURE:
      return {
        ...state,
        userUpdateProcess: false,
        userUpdateSuccess: false,
        error: action.error,
      };

    case actionTypes.UPDATE_COMPANYDATA_REQUEST:
      return {
        ...state,
        companyUpdateProcess: true,
      };
    case actionTypes.UPDATE_COMPANYDATA_SUCCESS:
      return {
        ...state,
        companyUpdateProcess: false,
        companyUpdateSuccess: true,
        company: action.companyData,
      };
    case actionTypes.UPDATE_COMPANYDATA_FAILURE:
      return {
        ...state,
        companyUpdateProcess: false,
        companyUpdateSuccess: false,
        error: action.error,
      };
    case actionTypes.DELETE_MEMBER_EMERGENCY_REQUEST:
      return {
        ...state,
        loading: true,
        deleteUserSuccess: false,
      };
    case actionTypes.DELETE_MEMBER_EMERGENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          emergency: state.currentUser.emergency.filter((item) => item._id !== action.emergencyItemId),
        },
        deleteUserSuccess: true,
      };
    case actionTypes.DELETE_MEMBER_EMERGENCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        deleteUserSuccess: false,
      };
    case actionTypes.DELETE_MEMBER_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        deleteUserSuccess: false,
      };
    case actionTypes.DELETE_MEMBER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          documents: state.currentUser.documents.filter((item) => item._id !== action.documentItemId),
        },
        deleteUserSuccess: true,
      };
    case actionTypes.DELETE_MEMBER_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        deleteUserSuccess: false,
      };
    default:
      return state;
  }
}
