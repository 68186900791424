import React from 'react';
// import PropTypes from 'prop-types';

import './Loading.css';

const Loading = () => (
  <div className="loadingStyle">
    <div className="lds-spinner lds">
      <div /><div /><div /><div /><div /><div />
      <div /><div /><div /><div /><div /><div />
    </div>
  </div>
);

// Loading.propTypes = {
//   margin: PropTypes.string
// }

export default Loading;
