import toastr from 'toastr';
import { OK, CONFLICT, NOT_FOUND, NO_CONTENT } from './statuses';

export function addCandidateNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The candidate already exists in the list!');
  } if (status === OK) {
    return toastr.success('Your application has been successfully submited!');
  }
}

export function deleteCandidateNote(status) {
  if (status === NO_CONTENT) {
    return toastr.success('The candidate was deleted successfully!');
  }
}

export function getCandidatesListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no candidates yet!');
  }
}

export function updateCandidateDataNote(status) {
  if (status === OK) {
    return toastr.success('The candidate data was edited successfully!');
  }
  if (status === CONFLICT) {
    return toastr.warning('The candidate already exists in the list!');
  }
}
