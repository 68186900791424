/* eslint-disable no-param-reassign */
import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  vacationsList: {
    totalPages: 1,
    currentPage: ' ',
    vacations: [],
  },
  vacationData: {},
  getListSuccess: false,
  addVacationSuccess: false,
  editVacationSuccess: false,
  deleteVacationSuccess: false,
  vacationsPerUser: [],
  vacationHistory: {
    totalPages: '',
    currentPage: 1,
    vacations: [],
  },
};

export default function vacationsReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_VACATIONSLIST_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_VACATIONSLIST_SUCCESS:
    const vacations = action.payload.vacations.map((vacation) => {
      vacation.firstName = _.get(vacation.member, 'firstName');
      vacation.lastName = _.get(vacation.member, 'lastName');
      return vacation;
    });
    return {
      ...state,
      loading: false,
      vacationsList: { ...action.payload, vacations },
      getListSuccess: true,
    };
  case actionTypes.GET_VACATIONSLIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getListSuccess: false,
    };

    case actionTypes.GET_VACATIONSPERUSER_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_VACATIONSPERUSER_SUCCESS:
    return {
      ...state,
      loading: false,
      vacationsPerUser: action.payload,
      getListSuccess: true,
    };
  case actionTypes.GET_VACATIONSPERUSER_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getListSuccess: false,
    };
    case actionTypes.GET_VACATIONSHISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        getListSuccess: false,
      };
    case actionTypes.GET_VACATIONSHISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        vacationsHistory: action.payload,
        getListSuccess: true,
      };
    case actionTypes.GET_VACATIONSHISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getListSuccess: false,
      };
  case actionTypes.ADD_VACATION_REQUEST:
    return {
      ...state,
      loading: true,
      addVacationSuccess: false,
    };
  case actionTypes.ADD_VACATION_SUCCESS:
    const newList = [...state.vacationsList.vacations];
    const newVacation = {
      ...action.vacationData,
      firstName: _.get(action.vacationData.member, 'firstName'),
      lastName: _.get(action.vacationData.member, 'lastName'),

    };
    newList.push(newVacation);
    return {
      ...state,
      loading: false,
      vacationsList: { ...state.vacationsList, vacations: newList },
      addVacationSuccess: true,
    };
  case actionTypes.ADD_VACATION_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addVacationSuccess: false,
    };
  case actionTypes.GET_VACATION_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case actionTypes.GET_VACATION_SUCCESS:
    return {
      ...state,
      loading: false,
      vacationData: {
        ...action.payload,

      },
    };
  case actionTypes.GET_VACATION_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case actionTypes.EDIT_VACATIONDATA_REQUEST:
    return {
      ...state,
      loading: true,
      editVacationSuccess: false,
    };
  case actionTypes.EDIT_VACATIONDATA_SUCCESS:
    const list = [...state.vacationsList.vacations];
    const id = action.vacationData._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = {
      ...action.vacationData,
      firstName: _.get(action.vacationData.member, 'firstName'),
      lastName: _.get(action.vacationData.member, 'lastName'),
    };
    return {
      ...state,
      loading: false,
      vacationsList: { ...state.vacationsList, vacations: list },
      editVacationSuccess: true,
    };
  case actionTypes.EDIT_VACATIONDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editVacationSuccess: false,
    };
  case actionTypes.EDIT_VACATIONSTATUS_REQUEST:
    return {
      ...state,
      loading: true,
      editVacationSuccess: false,
    };
  case actionTypes.EDIT_VACATIONSTATUS_SUCCESS: {
    const list = [...state.vacationsList.vacations];
    const id = action.vacationData._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = {
      ...action.vacationData,
      firstName: _.get(action.vacationData.member, 'firstName'),
      lastName: _.get(action.vacationData.member, 'lastName'),
    };
    return {
      ...state,
      loading: false,
      vacationsList: { ...state.vacationsList, vacations: list },
      editVacationSuccess: true,
    };
  }

  case actionTypes.EDIT_VACATIONSTATUS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editVacationSuccess: false,
    };
  case actionTypes.DELETE_VACATION_REQUEST:
    return {
      ...state,
      loading: true,
      deleteVacationSuccess: false,
    };
  case actionTypes.DELETE_VACATION_SUCCESS:
    return {
      ...state,
      loading: false,
      vacationsList: {
        ...state.vacationsList,
        vacations: state.vacationsList.vacations.filter(({ _id }) => _id !== action.vacationId),
      },
      deleteVacationSuccess: true,
    };
  case actionTypes.DELETE_VACATION_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteVacationSuccess: false,
    };

  default:
    return state;
  }
}
