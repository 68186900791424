import { loginNote, logoutNote } from './logIn_out';
import {
  addCandidateNote,
  deleteCandidateNote,
  getCandidatesListNote,
  updateCandidateDataNote,
} from './candidate';
import {
  addPartnerNote,
  deletePartnerDataNote,
  updatePartnerDataNote,
} from './partner';
import {
  updateUserDataNote,
  deleteUserDataNote,
  getUsersListNote,
  inviteMemberNote,
} from './user';

import {
  updateCurrentUserDataNote,
  changeUserPasswordNote,
  forgotPasswordNote,
  resetPasswordNote,
} from './currentUser';
import { updateCompanyDataNote } from './company';
import {
  getProjectListNote,
  addProjectNote,
  updateProjectDataNote,
  deleteProjectDataNote,
} from './project';
import {
  addVacationNote,
  deleteVacationDataNote,
  getVacationsListNote,
  updateVacationDataNote,
  approveDeclineVacationNote,
} from './vacation';
import {
  getTicketsListNote,
  addTicketNote,
  updateTicketDataNote,
  approveDeclineTicketNote,
  deleteTicketDataNote,
} from './ticket';
import {
  deleteEventNote,
  updateEventDataNote,
  addEventNote,
}
  from './event';
  import {
    deleteHolidayNote,
    getHolidaysNote,
    addHolidayNote,
    addBulkHolidaysNote,
  }
    from './holiday';
import {
  getVacancyListNote,
  addVacancyNote,
  updateVacancyDataNote,
  deleteVacancyDataNote,
} from './vacancy';
import {
  getTaskListNote,
  addTaskNote,
  updateTaskDataNote,
  deleteTaskDataNote,
  assignBulkTasksNote,
} from './task';
import {
  addCategoryNote,
  deleteCategoriesDataNote,
  getCategoriesListNote,
  updateCategoryNote,
} from './category';
import { deleteEmergencyDataNote } from './emergency';
import { deletePerformanceDataNote } from './performance';
import { deleteDocumentDataNote } from './document';

const notificationHandlers = {
  loginNote,
  logoutNote,
  addCandidateNote,
  deleteCandidateNote,
  getCandidatesListNote,
  updateCandidateDataNote,
  addPartnerNote,
  deletePartnerDataNote,
  updatePartnerDataNote,
  deleteUserDataNote,
  getUsersListNote,
  updateUserDataNote,
  changeUserPasswordNote,
  forgotPasswordNote,
  resetPasswordNote,
  inviteMemberNote,
  updateCompanyDataNote,
  getProjectListNote,
  addProjectNote,
  updateProjectDataNote,
  deleteProjectDataNote,
  addVacationNote,
  deleteVacationDataNote,
  getVacationsListNote,
  updateVacationDataNote,
  approveDeclineVacationNote,
  getTicketsListNote,
  addTicketNote,
  updateTicketDataNote,
  approveDeclineTicketNote,
  deleteTicketDataNote,
  deleteEventNote,
  updateEventDataNote,
  addEventNote,
  getVacancyListNote,
  addVacancyNote,
  updateVacancyDataNote,
  deleteVacancyDataNote,
  updateCurrentUserDataNote,
  getTaskListNote,
  addTaskNote,
  updateTaskDataNote,
  deleteTaskDataNote,
  assignBulkTasksNote,
  addCategoryNote,
  deleteCategoriesDataNote,
  getCategoriesListNote,
  updateCategoryNote,
  deleteEmergencyDataNote,
  deletePerformanceDataNote,
  deleteHolidayNote,
  getHolidaysNote,
  addHolidayNote,
  addBulkHolidaysNote,
  deleteDocumentDataNote,
};

export default notificationHandlers;
