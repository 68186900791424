import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  holidays: [],
  getHolidaysListSuccess: false,
  addHolidaySuccess: false,
  deleteHolidaySuccess: false,

};

export default function holidaysReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_HOLIDAYS_REQUEST:
    return {
      ...state,
      loading: true,
      getHolidaysListSuccess: false,
    };
  case actionTypes.GET_HOLIDAYS_SUCCESS:

    return {
      ...state,
      loading: false,
      holidays: action.payload,
      getHolidaysListSuccess: true,
    };
  case actionTypes.GET_HOLIDAYS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getHolidaysListSuccess: false,
    };

  case actionTypes.ADD_HOLIDAY_REQUEST:
    return {
      ...state,
      loading: true,
      addHolidaySuccess: false,
    };
  case actionTypes.ADD_HOLIDAY_SUCCESS:
    const newList = [...state.holidays];
    newList.push(action.holidayData);

    return {
      ...state,
      loading: false,
      holidays: newList,
      addHolidaySuccess: true,
    };
  case actionTypes.ADD_HOLIDAY_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addHolidaySuccess: false,
    };
    case actionTypes.ADD_BULKHOLIDAYS_REQUEST:
    return {
      ...state,
      loading: true,
      addHolidaySuccess: false,
    };
  case actionTypes.ADD_BULKHOLIDAYS_SUCCESS:
    const wholeList = [...state.holidays];
    wholeList.push(...action.payload);

    return {
      ...state,
      loading: false,
      holidays: wholeList,
      addHolidaySuccess: true,
    };
  case actionTypes.ADD_BULKHOLIDAYS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addHolidaySuccess: false,
    };

  case actionTypes.DELETE_HOLIDAY_REQUEST:
    return {
      ...state,
      loading: true,
      deleteHolidaySuccess: false,
    };
  case actionTypes.DELETE_HOLIDAY_SUCCESS:
    return {
      ...state,
      loading: false,
        ...state.holidays,
        holidays: _.filter(state.holidays, ({ _id }) => _id !== action.holidayId),
      deleteHolidaySuccess: true,
    };
  case actionTypes.DELETE_HOLIDAY_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteHolidaySuccess: false,
    };

  default:
    return state;
  }
}
