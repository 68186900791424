import toastr from 'toastr';
import { OK, CONFLICT } from './statuses';

export function updateCompanyDataNote(status) {
  if (status === OK) {
    return toastr.success('Company data was edited successfully!');
  }
  if (status === CONFLICT) {
    return toastr.warning('The company already exists in the list!');
  }
}
