import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  usersList: {
    totalPages: 1,
    currentPage: ' ',
    users: [],
  },
  recentlyInvitedUsersList: { users: [] },
  addListSuccess: false,
  getUserSuccess: false,
  deleteUserSuccess: false,
  userData: {},
  changePasswordProcess: false,
  changePasswordSuccess: false,
  birthdaysList: { users: [] },
  userUpdateProcess: false,
  userUpdateSuccess: false,
  sortValue: -1,
  status: 'active',
};

export default function membersReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_MEMBERSLIST_REQUEST:
      return {
        ...state,
        loading: true,
        [`${action.usersType}loading`]: true,
        addListSuccess: false,
      };
    case actionTypes.GET_MEMBERSLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        [`${action.usersType}loading`]: false,
        [action.usersType]: action.payload,
        addListSuccess: true,
      };
    case actionTypes.GET_MEMBERSLIST_FAILURE:
      return {
        ...state,
        loading: false,
        [`${action.usersType}loading`]: false,
        error: action.error,
        addListSuccess: false,
      };

    case actionTypes.GET_USERDATA_REQUEST:
      return {
        ...state,
        loading: true,
        getUserSuccess: false,
      };
    case actionTypes.GET_USERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.userData,
        getUserSuccess: true,
      };
    case actionTypes.GET_USERDATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getUserSuccess: false,
      };
    case actionTypes.UPDATE_USERDATA_REQUEST:
      return {
        ...state,
        userUpdateProcess: true,
        userUpdateSuccess: false,
      };
    case actionTypes.UPDATE_USERDATA_SUCCESS:
      return {
        ...state,
        userUpdateProcess: false,
        userUpdateSuccess: true,
        userData: action.payload,
      };
    case actionTypes.UPDATE_USERDATA_FAILURE:
      return {
        ...state,
        userUpdateProcess: false,
        userUpdateSuccess: false,
        error: action.error,
      };
    case actionTypes.INVITE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        getUserSuccess: false,
      };
    case actionTypes.INVITE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        getUserSuccess: true,
      };
    case actionTypes.INVITE_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        getUserSuccess: false,
      };

    case actionTypes.DELETE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        deleteUserSuccess: false,
      };
    case actionTypes.DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        usersList: {
          ...state.usersList,
          users: state.usersList.users.filter(({ _id }) => _id !== action.deleteUserId),
        },
        deleteUserSuccess: true,
      };
    case actionTypes.DELETE_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        deleteUserSuccess: false,
      };

    case actionTypes.DELETE_MEMBER_PERFORMANCE_REQUEST:
      return {
        ...state,
        loading: true,
        deleteUserSuccess: false,
      };
    case actionTypes.DELETE_MEMBER_PERFORMANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: {
          ...state.userData,
          performance: state.userData.performance.filter((item) => item._id !== action.performanceitemId),
        },
        deleteUserSuccess: true,
      };
    case actionTypes.DELETE_MEMBER_PERFORMANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        deleteUserSuccess: false,
      };

    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        changePasswordProcess: true,
        changePasswordSuccess: false,
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordProcess: false,
        changePasswordSuccess: true,
        currentUser: { ...state.currentUser },
      };
    case actionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordProcess: false,
        changePasswordSuccess: false,
        error: action.error,
      };
    case actionTypes.SORT_BY_GROW:
      return { ...state, sortValue: -1 };
    case actionTypes.SORT_BY_DECREASE:
      return { ...state, sortValue: 1 };
    case actionTypes.DELETE_MEMBER_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        deleteUserSuccess: false,
      };
    case actionTypes.DELETE_MEMBER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: {
          ...state.userData,
          documents: state.userData.documents.filter((item) => item._id !== action.documentItemId),
        },
        deleteUserSuccess: true,
      };
    case actionTypes.DELETE_MEMBER_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        deleteUserSuccess: false,
      };
    default:
      return state;
  }
}
