import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  partnersList: {
    totalPages: 1,
    currentPage: ' ',
    partners: [],
  },
  partnerData: {},
  getListSuccess: false,
  addPartnerSuccess: false,
  editPartnerSuccess: false,
  deletePartnerSuccess: false,
};

export default function partnersReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_PARTNERSLIST_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_PARTNERSLIST_SUCCESS:
    return {
      ...state,
      loading: false,
      partnersList: action.payload,
      getListSuccess: true,
    };
  case actionTypes.GET_PARTNERSLIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getListSuccess: false,
    };
  case actionTypes.ADD_PARTNER_REQUEST:
    return {
      ...state,
      loading: true,
      addPartnerSuccess: false,
    };
  case actionTypes.ADD_PARTNER_SUCCESS:
    const newList = [...state.partnersList.partners];
    newList.push(action.partnerData);
    return {
      ...state,
      loading: false,
      partnersList: { ...state.partnersList, partners: newList },
      addPartnerSuccess: true,
    };
  case actionTypes.ADD_PARTNER_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addPartnerSuccess: false,
    };
  case actionTypes.GET_PARTNERDATA_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case actionTypes.GET_PARTNERDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      partnerData: action.payload,
    };
  case actionTypes.GET_PARTNERDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case actionTypes.EDIT_PARTNERDATA_REQUEST:
    return {
      ...state,
      loading: true,
      editPartnerSuccess: false,
    };
  case actionTypes.EDIT_PARTNERDATA_SUCCESS:
    const list = [...state.partnersList.partners];
    const id = action.partnerData._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = action.partnerData;
    return {
      ...state,
      loading: false,
      partnersList: { ...state.partnersList, partners: list },
      partnerData: action.partnerData,
      editPartnerSuccess: true,
    };
  case actionTypes.EDIT_PARTNERDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editPartnerSuccess: false,
    };
  case actionTypes.DELETE_PARTNER_REQUEST:
    return {
      ...state,
      loading: true,
      deletePartnerSuccess: false,
    };
  case actionTypes.DELETE_PARTNER_SUCCESS:
    return {
      ...state,
      loading: false,
      partnersList: {
        ...state.partnersList,
        partners: state.partnersList.partners.filter(({ _id }) => _id !== action.partnerId),
      },
      deletePartnerSuccess: true,
    };
  case actionTypes.DELETE_PARTNER_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deletePartnerSuccess: false,
    };

  default:
    return state;
  }
}
