import toastr from 'toastr';
import { OK, CONFLICT, NOT_FOUND, METHOD_NOT_ALLOWED } from './statuses';

export function addTicketNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The ticket already exists');
  } if (status === OK) {
    return toastr.success('New ticket was created successfully!');
  }
}

export function deleteTicketDataNote(status) {
  if (status === OK) {
    return toastr.success('The ticket was deleted!');
  } if (status === METHOD_NOT_ALLOWED) {
    return toastr.warning('You can delete only your ticket');
  }
}

export function getTicketsListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no tickets yet!');
  }
}

export function updateTicketDataNote(status) {
  if (status === OK) {
    return toastr.success('The ticket data was edited!');
  } if (status === METHOD_NOT_ALLOWED) {
    return toastr.warning('You can edit only your ticket');
  } if (status === CONFLICT) {
    return toastr.warning('The ticket already exists');
  }
}

export function approveDeclineTicketNote(status) {
  if (status === OK) {
    return toastr.success('Status has changed');
  }
}
