import jwt from 'jsonwebtoken';
import _ from 'lodash';
import * as actionTypes from './store/actions/actionTypes';

const apiUrl = process.env.REACT_APP_API_URL;
export default class TokenApi {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  getToken() {
    const tokensJSONString = localStorage.getItem('tokens');
    const tokens = {};
    if (!_.isNil(tokensJSONString) && !_.isEmpty(tokensJSONString)) {
      _.assign(tokens, JSON.parse(localStorage.tokens));
    }
    const decodedjwt = jwt.decode(tokens.accessToken);
    const currentTime = new Date().getTime() / 1000;
    return new Promise((resolve, reject) => {
      if (!_.isNil(decodedjwt) && decodedjwt.exp < currentTime) {
        this.dispatch({ type: actionTypes.TOKEN_REFRESH_REQUEST });
        return fetch(`${apiUrl}/user/refresh-token`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${tokens.accessToken}`,
          },
          body: JSON.stringify({ refreshToken: tokens.refreshToken }),
        })
          .then((response) => (response.ok ? response.json() : Promise.reject(response)))
          .then((res) => {
            this.dispatch({
              type: actionTypes.TOKEN_REFRESH_SUCCESS,
              tokens: res,
            });
            localStorage.setItem('tokens', JSON.stringify(res));
            resolve();
          })
          .catch((error) => {
            this.dispatch({
              type: actionTypes.TOKEN_REFRESH_FAILURE,
              error: error.toString(),
            });
            localStorage.removeItem('tokens');
            reject();
          });
      }
      return resolve();
    });
  }
}
