import toastr from 'toastr';
import { CONFLICT, NOT_FOUND, NOT_ACCEPTABLE, UNAVAILABLE_FOR_LEGAL_REASONS, SERVER_ERROR } from './statuses';

toastr.options = {
  closeButton: true,
  positionClass: 'toast-top-center',
  preventDuplicates: false,
  onclick: null,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 5000,
};
export function loginNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('Not valid user');
  } if (status === CONFLICT) {
    return toastr.warning('Wrong password input.');
  } if (status === NOT_ACCEPTABLE) {
    return toastr.warning('Your email address is not verified. Please verify to continue.');
  } if (status === UNAVAILABLE_FOR_LEGAL_REASONS) {
    return toastr.warning('Your account has been suspended or deleted by admin.');
  } if (status === SERVER_ERROR) {
    return toastr.error('Problem with network connection. Please try later ^_^');
  }
}

export function logoutNote(status) {
  if (status === SERVER_ERROR) {
    return toastr.error('Problem with network connection. Please try later ^_^');
  }
}
