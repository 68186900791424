import toastr from 'toastr';
import { NOT_FOUND, NO_CONTENT, CREATED, CONFLICT, OK } from './statuses';

export function deleteUserDataNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There is no user with that data or has already deleted!.');
  } if (status === NO_CONTENT) {
    return toastr.success('The member was deleted successfully!');
  }
}

export function getUsersListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('The are no members yet!');
  }
}

export function updateUserDataNote(status) {
  if (status === OK) {
    return toastr.success('The member data was edited successfully!');
  } if (status === CONFLICT) {
    return toastr.warning('Email address already exists.');
  }
}

export function inviteMemberNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('Email address already exists.');
  } if (status === CREATED) {
    return toastr.success('Invitation has been sent to the indicated email');
  }
}
