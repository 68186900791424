import toastr from 'toastr';
import { NO_CONTENT, NOT_FOUND } from './statuses';

export function deleteEmergencyDataNote(status) {
  if (status === NO_CONTENT) {
    return toastr.success('Selected emergency contact was deleted successfully!');
  }
  if (status === NOT_FOUND) {
    return toastr.warning('There is no valid emergency contact selected for deletion!');
  }
}
