import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  projectsList: {
    totalPages: 1,
    currentPage: ' ',
    projects: [],
  },
  projectData: {},
  getListSuccess: false,
  addProjectSuccess: false,
  editProjectSuccess: false,
  deleteProjectSuccess: false,
};

export default function projectsReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_PROJECTSLIST_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_PROJECTSLIST_SUCCESS:
    return {
      ...state,
      loading: false,
      projectsList: action.payload,
      getListSuccess: true,
    };
  case actionTypes.GET_PROJECTSLIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getListSuccess: false,
    };

  case actionTypes.ADD_PROJECT_REQUEST:
    return {
      ...state,
      loading: true,
      addProjectSuccess: false,
    };
  case actionTypes.ADD_PROJECT_SUCCESS:
    return {
      ...state,
      loading: false,
      projectsList: { ...state.projectsList, projects: [...state.projectsList.projects, action.payload] },
      addProjectSuccess: true,
    };
  case actionTypes.ADD_PROJECT_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addProjectSuccess: false,
    };
  case actionTypes.GET_PROJECTDATA_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case actionTypes.GET_PROJECTDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      projectData: action.payload,
    };
  case actionTypes.GET_PROJECTDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case actionTypes.EDIT_PROJECTDATA_REQUEST:
    return {
      ...state,
      loading: true,
      editProjectSuccess: false,
    };
  case actionTypes.EDIT_PROJECTDATA_SUCCESS: {
    const list = [...state.projectsList.projects];
    const id = action.projectData._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = action.projectData;
    return {
      ...state,
      loading: false,
      projectsList: { ...state.projectsList, projects: list },
      projectData: action.projectData,
      editProjectSuccess: true,
    };
  }

  case actionTypes.EDIT_PROJECTDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editProjectSuccess: false,
    };
  case actionTypes.DELETE_PROJECT_REQUEST:
    return {
      ...state,
      loading: true,
      deleteProjectSuccess: false,
    };
  case actionTypes.DELETE_PROJECT_SUCCESS:
    return {
      ...state,
      loading: false,
      projectsList: {
        ...state.projectsList,
        projects: state.projectsList.projects.filter(({ _id }) => _id !== action.projectId),
      },
      deleteProjectSuccess: true,
    };
  case actionTypes.DELETE_PROJECT_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteProjectSuccess: false,
    };

  default:
    return state;
  }
}
