import toastr from 'toastr';
import { OK, CONFLICT, NOT_FOUND, METHOD_NOT_ALLOWED, CREATED, NO_CONTENT } from './statuses';

export function addVacationNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The same vacation already exists');
  } if (status === CREATED) {
    return toastr.success('Vacation was created successfully!');
  }
}

export function deleteVacationDataNote(status) {
  if (status === NO_CONTENT) {
    return toastr.success('The vacation was deleted!');
  } if (status === METHOD_NOT_ALLOWED) {
    return toastr.warning('You can delete only your own vacation');
  }
}

export function getVacationsListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no vacations yet!');
  }
}

export function updateVacationDataNote(status) {
  if (status === OK) {
    return toastr.success('The vacation data was edited!');
  } if (status === METHOD_NOT_ALLOWED) {
    return toastr.warning('You can edit only your vacation');
  } if (status === CONFLICT) {
    return toastr.warning('The same vacation already exists');
  }
}

export function approveDeclineVacationNote(status) {
  if (status === OK) {
    return toastr.success('Status has changed successfully!');
  }
}
