import jwt from 'jsonwebtoken';
import _ from 'lodash';

export default function getUserId() {
  const tokensJSONString = localStorage.getItem('tokens');

  const tokens = {};
  if (!_.isNil(tokensJSONString) && !_.isEmpty(tokensJSONString)) {
    _.assign(tokens, JSON.parse(localStorage.tokens));
  }
  const decodedjwt = jwt.decode(tokens.accessToken);

  return decodedjwt ? decodedjwt._id : '';
}
