import toastr from 'toastr';
import { NOT_FOUND, OK, NO_CONTENT, CREATED, CONFLICT } from './statuses';

export function addPartnerNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The partner email already exists!');
  } if (status === CREATED) {
    return toastr.success('New partner was created successfully!');
  }
}

export function deletePartnerDataNote(status) {
  if (status === NO_CONTENT) {
    return toastr.success('The partner was deleted successfully!');
  }
}

export function updatePartnerDataNote(status) {
  if (status === OK) {
    return toastr.success('The partner data was edited successfully!');
  }
  if (status === CONFLICT) {
    return toastr.warning('The partner already exists in the list!');
  }
}

export function getPartnersListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no partners yet');
  }
}
