import _ from 'lodash';
import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  pastEvents: {
    totalPages: 1,
    currentPage: ' ',
    events: [],
  },
  upcomingEvents: {
    totalPages: 1,
    currentPage: ' ',
    events: [],
  },
  eventData: {},
  getPastEventsSuccess: false,
  getUpcomingEventsSuccess: false,
  getEventSuccess: false,
  addEventSuccess: false,
  updateEventSuccess: false,
  deleteEventSuccess: false,

};

export default function eventsReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_PASTEVENTS_REQUEST:
    return {
      ...state,
      loading: true,
      getPastEventsSuccess: false,
    };
  case actionTypes.GET_PASTEVENTS_SUCCESS:

    return {
      ...state,
      loading: false,
      pastEvents: action.payload,
      getPastEventsSuccess: true,
    };
  case actionTypes.GET_PASTEVENTS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      pastEvents: {},
      getPastEventsSuccess: false,
    };

  case actionTypes.GET_UPCOMINGEVENTS_REQUEST:
    return {
      ...state,
      loading: true,
      getUpcomingEventsSuccess: false,
    };
  case actionTypes.GET_UPCOMINGEVENTS_SUCCESS:
    return {
      ...state,
      loading: false,
      upcomingEvents: action.payload,
      getUpcomingEventsSuccess: true,
    };
  case actionTypes.GET_UPCOMINGEVENTS_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      upcomingEvents: {},
      getUpcomingEventsSuccess: false,
    };

  case actionTypes.ADD_EVENT_REQUEST:
    return {
      ...state,
      loading: true,
      addEventSuccess: false,
    };
  case actionTypes.ADD_EVENT_SUCCESS:
    const newList = [...state.upcomingEvents.events];
    newList.push(action.eventData);

    return {
      ...state,
      loading: false,
      upcomingEvents: { ...state.upcomingEvents, events: newList },
      addEventSuccess: true,
    };
  case actionTypes.ADD_EVENT_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addEventSuccess: false,
    };

  case actionTypes.UPDATE_EVENTDATA_REQUEST:
    return {
      ...state,
      loading: true,
      updateEventSuccess: false,
    };
  case actionTypes.UPDATE_EVENTDATA_SUCCESS:
    const list = [...state.upcomingEvents.events];
    const updatedEvent = {
      ...action.eventData,
    };

    const id = updatedEvent._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = updatedEvent;
    return {
      ...state,
      loading: false,
      updateEventSuccess: true,
      upcomingEvents: { ...state.upcomingEvents, events: list },
    };
  case actionTypes.UPDATE_EVENTDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      updateEventSuccess: false,
    };

  case actionTypes.DELETE_EVENT_REQUEST:
    return {
      ...state,
      loading: true,
      deleteEventSuccess: false,
    };
  case actionTypes.DELETE_EVENT_SUCCESS:
    return {
      ...state,
      loading: false,
      upcomingEvents: {
        ...state.upcomingEvents,
        events: _.filter(state.upcomingEvents.events, ({ _id }) => _id !== action.eventId),
      },
      deleteEventSuccess: true,
    };
  case actionTypes.DELETE_EVENT_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteEventSuccess: false,
    };

  default:
    return state;
  }
}
