import * as actionTypes from '../actions/actionTypes';

const defaultState = {
  loading: false,
  vacanciesList: {
    totalPages: 1,
    currentPage: ' ',
    vacancies: [],
  },
  vacancyData: {},
  getListSuccess: false,
  addVacancySuccess: false,
  editVacancySuccess: false,
  deleteVacancySuccess: false,
};

export default function vacanciesReducer(state = defaultState, action) {
  switch (action.type) {
  case actionTypes.GET_VACANCIESLIST_REQUEST:
    return {
      ...state,
      loading: true,
      getListSuccess: false,
    };
  case actionTypes.GET_VACANCIESLIST_SUCCESS:
    return {
      ...state,
      loading: false,
      vacanciesList: action.payload,
      getListSuccess: true,
    };
  case actionTypes.GET_VACANCIESLIST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      getListSuccess: false,
    };

  case actionTypes.ADD_VACANCY_REQUEST:
    return {
      ...state,
      loading: true,
      addVacancySuccess: false,
    };
  case actionTypes.ADD_VACANCY_SUCCESS:
    return {
      ...state,
      loading: false,
      vacanciesList: { ...state.vacanciesList, vacancies: [...state.vacanciesList.vacancies, action.payload] },
      addVacancySuccess: true,
    };
  case actionTypes.ADD_VACANCY_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      addVacancySuccess: false,
    };
  case actionTypes.GET_VACANCYDATA_REQUEST:
    return {
      ...state,
      loading: true,
    };
  case actionTypes.GET_VACANCYDATA_SUCCESS:
    return {
      ...state,
      loading: false,
      vacancyData: action.payload,
    };
  case actionTypes.GET_VACANCYDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  case actionTypes.EDIT_VACANCYDATA_REQUEST:
    return {
      ...state,
      loading: true,
      editVacancySuccess: false,
    };
  case actionTypes.EDIT_VACANCYDATA_SUCCESS: {
    const list = [...state.vacanciesList.vacancies];
    const id = action.vacancyData._id;
    const index = list.findIndex(({ _id }) => _id === id);
    list[index] = action.vacancyData;
    return {
      ...state,
      loading: false,
      vacanciesList: { ...state.vacanciesList, vacancies: list },
      vacancyData: action.vacancyData,
      editVacancySuccess: true,
    };
  }

  case actionTypes.EDIT_VACANCYDATA_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      editVacancySuccess: false,
    };
  case actionTypes.DELETE_VACANCY_REQUEST:
    return {
      ...state,
      loading: true,
      deleteVacancySuccess: false,
    };
  case actionTypes.DELETE_VACANCY_SUCCESS:
    return {
      ...state,
      loading: false,
      vacanciesList: {
        ...state.vacanciesList,
        vacancies: state.vacanciesList.vacancies.filter(({ _id }) => _id !== action.vacancyId),
      },
      deleteVacancySuccess: true,
    };
  case actionTypes.DELETE_VACANCY_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.error,
      deleteVacancySuccess: false,
    };

  default:
    return state;
  }
}
