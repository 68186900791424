import toastr from 'toastr';
import { OK, CREATED, CONFLICT, NO_CONTENT, NOT_FOUND } from './statuses';

export function addCategoryNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('Category with the same name is already created.');
  } if (status === CREATED) {
    return toastr.success('New Category was created successfully!');
  }
}

export function deleteCategoriesDataNote(status) {
  if (status === NO_CONTENT) {
    return toastr.success('Selected categories were deleted successfully!');
  }
  if (status === NOT_FOUND) {
    return toastr.warning('There is no category selected for deletion!');
  }
}

export function getCategoriesListNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no categories yet!');
  }
}

export function updateCategoryNote(status) {
  if (status === OK) {
    return toastr.success('The category data was edited successfully!');
  } if (status === CONFLICT) {
    return toastr.warning('The task is already included in the category!');
  }
  if (status === NOT_FOUND) {
    return toastr.warning('There is no category with that Id!');
  }
}
