import React, { Component } from 'react';
import _ from 'lodash';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select-me/lib/ReactSelectMe.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PublicRoute from './routes/PublicRoute';

import getCurrentUser from './store/actions/getCurrentUser';
import Loading from './assets/loadingSpinner/Loading';
import ThankYou from './views/pages/thankYou';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const EmailVerification = React.lazy(() => import('./views/pages/invite/EmailVerification'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Vacancy = React.lazy(() => import('./views/pages/vacancies/VacancyPage'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'));
const CVPreview = React.lazy(() => import('./views/pages/candidates/CVPreview'));
const DocumentPreview = React.lazy(() => import('./views/pages/userPages/DocumentPreview'));
class App extends Component {
  componentDidMount() {
    const { getCurrentUser, userId } = this.props;
    if (!_.isEmpty(userId)) {
      getCurrentUser(userId);
    }
  }

  render() {
    const { isSignedIn } = this.props;
    return (
      <>
        <React.Suspense fallback={<Loading />}>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/email-verification/:hash"
                name="Email verification"
                render={(props) => <EmailVerification {...props} />}
              />
              <PublicRoute
                exact
                path="/login"
                name="Login"
                isSignedIn={isSignedIn}
                component={Login}
              />
              <PublicRoute
                exact
                path="/vacancy/:id"
                name="Vacancy"
                component={Vacancy}
              />
              <PublicRoute
                exact
                path="/forgot-password"
                name="ForgotPassword"
                component={ForgotPassword}
              />
               <PublicRoute
                 exact
                 path="/reset-password"
                 name="ResetPassword"
                 component={ResetPassword}
               />
              <PublicRoute
                exact
                path="/cv/preview"
                name="CV Preview"
                component={CVPreview}
              />
                 <PublicRoute
                   exact
                   path="/document/preview"
                   name="Document Preview"
                   component={DocumentPreview}
                 />
                     <PublicRoute
                       exact
                       path="/thank-you"
                       name="Thank you!"
                       component={ThankYou}
                     />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <TheLayout {...this.props} />
            </Switch>
          </BrowserRouter>
        </React.Suspense>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.authReducer.currentUser._id,
  isSignedIn: !_.isNil(state.authReducer.currentUser._id) && !_.isEmpty(state.authReducer.currentUser._id),

});
const mapDispatchToProps = {
  getCurrentUser,
};

App.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
