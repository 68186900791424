import toastr from 'toastr';
import { OK, CONFLICT, NOT_FOUND } from './statuses';

export function addEventNote(status) {
  if (status === CONFLICT) {
    return toastr.warning('The Event already exists in the list!');
  } if (status === OK) {
    return toastr.success('New Event was created successfully!');
  }
}

export function deleteEventNote(status) {
  if (status === OK) {
    return toastr.success('The Event was deleted successfully!');
  }
}

export function getPastEventsNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no Past Events yet!');
  }
}
export function getUpcomingEventsNote(status) {
  if (status === NOT_FOUND) {
    return toastr.warning('There are no Upcoming Events yet!');
  }
}
export function updateEventDataNote(status) {
  if (status === OK) {
    return toastr.success('The Event data was updated successfully!');
  } if (status === CONFLICT) {
    return toastr.warning('The Event already exists in the list!');
  }
}
